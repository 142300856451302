<template>
  <div id="englishCourses">
    <section
      class="page-header"
      style="background-image: url('/imgs/courses-01.png')"
    >
      <div class="caption">
        <i class="isologo"></i>
        <h1>{{ $lang("title") }}</h1>
      </div>
    </section>
    <section class="bg-tertiary py-6 text-center text-white decoration-3">
      <div class="container" v-html="$lang('desc')" />
    </section>

    <section class="py-6 container">
      <div class="row">
        <div class="container mb-6 col-md-6">
          <AccordionGroup>
            <AccordionItem
              :key="$lang('courses.semi_intensive.title')"
              @onClick="setActive(`${$lang('courses.semi_intensive.details')}`)"
              :active="
                activeIndex === `${$lang('courses.semi_intensive.details')}`
              "
            >
              <template #title>{{
                $lang("courses.semi_intensive.title")
              }}</template>
              <template #body>
                <div class="row lh-5 text-medium">
                  <div
                    class="col-md-12"
                    v-html="$lang('courses.semi_intensive.details')"
                    v-if="$lang('courses.semi_intensive.details')"
                  />
                </div>
                <button
                  class="btn btn-primary px-4 mx-auto mt-5"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("courses.semi_intensive.button") }}
                </button>
              </template>
            </AccordionItem>
          </AccordionGroup>
        </div>
        <div class="container mb-6 col-md-6">
          <AccordionGroup>
            <AccordionItem
              :key="$lang('courses.afternoon.title')"
              @onClick="setActive(`${$lang('courses.afternoon.details')}`)"
              :active="activeIndex === `${$lang('courses.afternoon.details')}`"
            >
              <template #title>{{ $lang("courses.afternoon.title") }}</template>
              <template #body>
                <div class="row lh-5 text-medium">
                  <div
                    class="col-md-12"
                    v-html="$lang('courses.afternoon.details')"
                    v-if="$lang('courses.afternoon.details')"
                  />
                </div>
                <button
                  class="btn btn-primary px-4 mx-auto mt-5"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("courses.afternoon.button") }}
                </button>
              </template>
            </AccordionItem>
          </AccordionGroup>
        </div>
        <div class="container col-md-12">
          <AccordionGroup>
            <AccordionItem
              :key="$lang('courses.private.title')"
              @onClick="setActive(`${$lang('courses.private.details')}`)"
              :active="activeIndex === `${$lang('courses.private.details')}`"
            >
              <template #title>{{ $lang("courses.private.title") }}</template>
              <template #body>
                <div class="row lh-5 text-medium">
                  <div
                    class="col-md-12"
                    v-html="$lang('courses.private.details')"
                    v-if="$lang('courses.private.details')"
                  />
                </div>
                <button
                  class="btn btn-primary px-4 mx-auto mt-5"
                  @click="$router.push('/contact-us')"
                >
                  {{ $lang("courses.private.button") }}
                </button>
              </template>
            </AccordionItem>
          </AccordionGroup>
        </div>
      </div>

      <button
        class="btn btn-tertiary text-white px-5 mx-auto mt-5"
        @click="$router.push('/contact-us')"
      >
        {{ $lang("button") }}
      </button>
    </section>
  </div>
</template>

<script>
import AccordionGroup from "@/components/AccordionGroup.vue";
import AccordionItem from "@/components/AccordionItem.vue";

export default {
  components: {
    AccordionGroup,
    AccordionItem,
  },
  data: () => ({
    activeIndex: null,
  }),
  methods: {
    setActive(index) {
      if (this.activeIndex === index) this.activeIndex = null;
      else this.activeIndex = index;
    },
  },
};
</script>
